<template>
  <el-form-item
    prop="function_args"
    :label="__('Arguments')"
    style="margin: 0;"
    class="functionArgumentsTable"
  >
    <el-table fit :data="inputVal" style="width: 100%">
      <el-table-column :label="__('NAME')">
        <template slot-scope="scope">
          <el-form-item class="no-red-highlight">
            <javascript-identifier-input
              v-model="scope.row.name"
              @input="onInputChangeHandler"
            />
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="__('TYPE')">
        <template slot-scope="scope">
          <el-form-item class="no-red-highlight">
            <javascript-type-selector
              v-model="scope.row.parameter_type"
              @change="onInputChangeHandler"
            />
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column prop="description" :label="__('DESCRIPTION')">
        <template slot-scope="scope">
          <el-form-item>
            <el-input
              v-model="scope.row.description"
              @input="onInputChangeHandler"
            />
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column prop="parameter_test_value" :label="__('TEST VALUE')">
        <template slot-scope="scope">
          <el-form-item>
            <el-input
              v-model="scope.row.parameter_test_value"
              @input="onInputChangeHandler"
            />
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column class-name="cell-item-pointer" width="50px">
        <template slot-scope="scope">
          <span @click="removeFunctionArgument(scope.$index)">
            <i class="el-icon-circle-close"></i>
          </span>
        </template>
      </el-table-column>
    </el-table>
  </el-form-item>
</template>

<script>
import JavascriptTypeSelector from "./TypeSelector";
import JavascriptIdentifierInput from "./JavascriptIdentifierInput";
import Vue from "vue";

export default {
  components: {
    JavascriptTypeSelector: JavascriptTypeSelector,
    JavascriptIdentifierInput: JavascriptIdentifierInput
  },
  methods: {
    onInputChangeHandler(event) {
      this.$emit("change", event);
    },
    removeFunctionArgument(idx) {
      Vue.delete(this.inputVal, idx);
      this.$emit("change", null);
    }
  },
  props: ["value", "argLimit"],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";

.functionArgumentsTable ::v-deep .el-table::before {
  background-color: white !important;
}

.functionArgumentsTable ::v-deep .el-form-item--medium {
  padding: 15px 0 0 0;
  margin-bottom: 0;
}

.functionArgumentsTable ::v-deep .el-table--medium th {
  padding: 0;
}

.el-table {
  .el-form-item {
    padding-bottom: 15px;
  }
}

.functionArgumentsTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.functionArgumentsTable {
  ::v-deep .no-red-highlight .el-input__inner,
  ::v-deep .no-red-highlight .el-textarea__inner,
  ::v-deep .empty-row .el-input__inner,
  ::v-deep .empty-row .el-textarea__inner,
  ::v-deep .non-empty-val .el-input__inner,
  ::v-deep .non-empty-val .el-textarea__inner {
    border-color: $--border-color-base !important;

    &:focus {
      border-color: $--color-text-regular !important;
    }
  }
}

::v-deep .el-input__inner {
  background: $--background-color-base !important;
  border-color: $--border-color-base !important;

  &:focus {
    border-color: $--color-text-regular !important;
  }
}

.form-container {
  min-width: 450px;
}

.form-test-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.form-editor-container {
  padding: 0 20px;
  background: #262831;
  border-radius: 4px;
  margin-bottom: 15px;
}

.ace_editor_dummy-text {
  padding: 10px 0;
  background: transparent;
}

.form-editor-container-functionArgRow {
  margin: 15px;
}

.form-editor-container-functionArgRow:last-of-type {
  margin-bottom: 0;
}

.form-editor-container-functionArgRow .actionCol > div {
  display: none;
}

.form-editor-container-functionArgRow:hover .actionCol > div {
  display: block;
}
</style>
