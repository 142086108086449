<template>
  <el-input
    v-model="inputVal"
    v-permit="permittedCharactersRegex"
    @input="onValueChangeHandler"
    ref="inputBox"
  />
</template>

<script>
import { alphaNumExclSpaceRegex } from "@/utils/regex";

export default {
  data() {
    return {
      permittedCharactersRegex: alphaNumExclSpaceRegex,
      selectedType: null
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  props: ["value"],
  methods: {
    onValueChangeHandler(event) {
      if (event.match(/^[0-9]/)) {
        this.inputVal = event.replace(/^\d+/, "");
        setTimeout(() => {
          this.$refs.inputBox.$el.firstElementChild.focus();
          this.$refs.inputBox.$el.firstElementChild.setSelectionRange(0, 0);
        }, 10);
        return;
      }

      this.$emit("change", event);
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped></style>
