<template>
  <el-select
    v-model="inputVal"
    filterable
    default-first-option
    style="width: 100%;"
    @change="$emit('change', selectedType)"
  >
    <el-option
      v-for="type in availableTypes"
      :label="beautifyTypeName(type)"
      :value="type"
      :key="type"
    />
  </el-select>
</template>

<script>
import JSTypes from "@/utils/JsContentFnTypeHelper";

export default {
  data() {
    return {
      selectedType: null
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },

    availableTypes() {
      return JSTypes;
    }
  },
  props: ["value"],
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    beautifyTypeName(type) {
      return type
        .split("_")
        .map(str => {
          const doNotLowerCase = ["USD", "GBP", "GPB", "EUR"];

          if (str === "GPB") return "GBP";
          if (doNotLowerCase.indexOf(str) !== -1) return str;

          return this.capitalizeFirstLetter(str.toLowerCase());
        })
        .join(" ");
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped></style>
