import { render, staticRenderFns } from "./JavascriptIdentifierInput.vue?vue&type=template&id=303b6d56&scoped=true"
import script from "./JavascriptIdentifierInput.vue?vue&type=script&lang=js"
export * from "./JavascriptIdentifierInput.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "303b6d56",
  null
  
)

export default component.exports